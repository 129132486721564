//Colors
$color-gray-light-0: #e1e1e1;
$color-white: #ffffff;
$color-black: #000000;

$color-primary: #092758;
$color-secondary: #ffd151;

$color-success: #4caf50;

//Overlays
$dark-blue-overlay: rgba(0, 2, 35, 0.34);

//Gaps
$gap-xl: 5rem;
$gap-lg: 2.8rem;
$gap-md: 2rem;
$gap-sm: 1.2rem;
$gap-xs: 0.8rem;

//Font
$font-size-xs: 1.35rem;
$font-size-sm: 1.45rem;
$font-size-normal: 1.6rem;
$font-size-md: 2rem;
$font-size-lg: 2.4rem;
$font-size-xl: 2.8rem;
$font-size-xxl: 4.5rem;

//Shadows
$shadow-small: 0px 3px 4px rgba($color-black, 0.5);
$shadow-medium: 0px 4px 6px rgba($color-black, 1);

//Paddings
$block-element-padding-sm: 1.2rem 4rem;
