.moz-p-offer {
  margin-bottom: -10rem;

  @include respond(phone) {
    margin-bottom: -20rem;
  }

  &__hero-img {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-image: url(../../img/offer/offer-hero.jpg);
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.45);
    position: relative;
    height: 50vh;
  }

  &__container {
    transform: translateY(-20rem);
    @include respond(phone) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__header {
    padding-top: $gap-sm;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__header-title {
    font-family: "proza-libre";
    font-size: 3.5rem;
  }

  &__single-price {
    padding-right: 3.2rem;
    font-size: $font-size-lg;
  }

  &__image-item {
    height: 100%;
    @include centerChildren;

    &:hover {
      cursor: zoom-in;
    }
  }

  &__carousel {
    height: 400;
    overflow: hidden;
    background-color: lighten(rgba($color-primary, 0.3), 20%);
  }

  .carousel .carousel-control-prev-icon,
  .carousel .carousel-control-next-icon {
    transform: scale(2.2);
    -webkit-filter: drop-shadow(0px 0px 1px $color-primary);
    filter: drop-shadow(0px 0px 1px $color-primary);
  }

  &__table {
    font-size: $font-size-normal;

    table td,
    table th {
      font-size: $font-size-xs;
    }

    ::-webkit-scrollbar {
      height: 8px;
      background-color: lighten($color-primary, 75%);
    }
  }

  .moz-c-pricelist {
    margin: 0;
    margin-top: 2rem;
  }
}
