*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
}

body {
  box-sizing: border-box;
}

a {
  &,
  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    border: none;
    cursor: pointer;
  }
}
