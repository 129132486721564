.moz-p-documents {
  &__header {
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    background-image: url(../../img/documents/documents-hero.jpg);
  }

  &__header-content {
    padding: 16rem 0;
    text-align: center;
    color: $color-white;
  }

  &__list-wrapper {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    margin: 4rem 0;
  }

  &__list {
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: $gap-sm;
    }

    @include respond(phone) {
      margin: auto 2rem !important;
    }
  }

  &__list-item {
    font-size: $font-size-normal;
    box-shadow: $shadow-small;
  }
}
