@font-face {
  font-family: "proza-libre";
  src: url(../../fonts/ProzaLibre-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proza-libre-bold";
  src: url(../../fonts/ProzaLibre-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "open-sans";
  src: url(../../fonts/OpenSans-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "open-sans-italic";
  src: url(../../fonts/OpenSans-Italic.ttf);
  font-style: normal;
  font-weight: 400;
}

body {
  font-family: "open-sans", sans-serif;
  font-weight: 400;
  line-height: 1.8;
}

.heading-primary {
  font-family: "proza-libre";
  font-size: 7.5rem;
  text-transform: uppercase;

  @include respond(tab-land) {
    font-size: 6rem;
  }

  @include respond(phone) {
    font-size: 4.5rem;
  }
}

.heading-secondary {
  font-family: "proza-libre";
  font-size: $font-size-xxl;
}

.heading-tertiary {
  font-family: "proza-libre";
  font-size: $font-size-xl !important;
}

.heading-small {
  font-family: "proza-libre";
  font-size: $font-size-md;
}

.paragraph {
  font-family: "open-sans";
  font-size: $font-size-normal !important;

  &--lead {
    font-size: $font-size-md;
  }

  &--small {
    font-size: $font-size-sm;
  }
}
