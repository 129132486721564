.moz-l-navbar {
  font-size: $font-size-normal;
  padding: 6px 0 !important;
  z-index: 150;
  margin-top: 0 !important;

  @include respond(phone) {
    font-size: $font-size-md;
    margin-top: $gap-sm;
    padding: 6px 1rem !important;
  }

  &__brand {
    @include centerChildren;
  }

  &__brand-image {
    margin-right: $gap-sm;
  }

  &__brand-name {
    font-family: "proza-libre";
    font-size: $font-size-lg;
  }

  &__dropdown {
    font-size: $font-size-normal;
  }

  &__dropdown-item {
    text-transform: uppercase;
    padding: 0 $gap-xs;
    @include respond(tab-land) {
      padding: 0;
    }
  }

  &__item {
    padding: 0 $gap-xs;
    transition: transform 0.3s ease !important;
    text-transform: uppercase;

    &:hover {
      transform: translateY(-2px);

      .moz-l-navbar__item-link,
      .moz-l-navbar__dropdown-title {
        color: $color-white !important;
        background: rgba($color-gray-light-0, 0.1);
      }
    }

    @include respond(tab-land) {
      padding: 0;
    }
  }
}
