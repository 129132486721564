.moz-p-root-dest {
  padding-bottom: 4rem;

  &__header {
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    background-image: url(../../img/destinations/destination-hero.jpg);
  }

  &__header-content {
    @include centerChildren;
    flex-direction: column;
    color: $color-white;
    padding: 12rem 0;

    h1 {
      text-shadow: $shadow-small;
    }
  }
}
