.moz-p-about {
  font-size: $font-size-normal;
  &__header {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-image: url(../../img/about/about-us.jpg);
  }

  &__header-content-wrapper {
    @include centerChildren;
    text-align: center;
    color: $color-white;
  }

  &__header-content {
    padding: 14rem 0;
  }

  &__counter {
    padding-top: 2.5rem;
    margin-top: -8rem;
    z-index: 10;
    text-align: center;
  }

  &__counter-item {
    @include centerChildren;
    flex-direction: column;
    padding: 3rem;
    text-transform: uppercase;
  }

  &__description-wrapper {
    margin-top: 5.5rem;
    margin-bottom: 6.5rem;
    padding-left: 2.4rem;
  }
}
