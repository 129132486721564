.moz-p-home-hero {
  padding-top: 10rem;

  @include respond(tab-port) {
    padding-bottom: 10rem;
  }

  @include centerChildren;
  flex-direction: column;
  text-align: center;
  color: $color-white;
  background-color: $dark-blue-overlay;
  position: relative;

  &__heading {
    text-shadow: $shadow-small;
    letter-spacing: 0.2rem;
    font-weight: 400;

    &--emphazed {
      color: $color-secondary;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
      font-size: 8.2rem;

      @include respond(phone) {
        font-size: 5rem;
      }
    }
  }

  &__button-wraper {
    display: flex;
    align-items: stretch;
  }

  &__button {
    font-size: $font-size-md !important;
    padding: $block-element-padding-sm !important;
    display: block;
    border: 2px solid $color-primary;
  }

  &__action-box {
    position: absolute;
    bottom: 50px;

    &--phone {
      right: 24px;
    }
    &--social {
      left: 24px;
      @include respond(phone) {
        display: flex;
        top: 10rem;
      }

      @media only screen and (max-width: 542px) {
        display: block;
      }

      @include respond(small-phone) {
        display: none;
      }
    }
  }

  &__action-box-item {
    font-size: $font-size-md;
    margin-bottom: 0;
    padding-top: $gap-sm;

    &--social {
      font-size: $font-size-normal;
      opacity: 0.8;
      transition: opacity 0.3s ease-in;
      &:hover {
        opacity: 1;
      }

      @include respond(phone) {
        margin-right: 1rem;
      }
    }

    &--phone {
      font-size: $font-size-lg;
      display: inline-block;
      margin-left: $gap-sm;
    }
  }

  &__lead {
    font-size: $font-size-lg;
    margin-top: $gap-lg;
    text-shadow: $shadow-small;
    @include respond(phone) {
      font-size: $font-size-md;
      margin-top: $gap-sm;
    }
  }
}

.moz-p-home-actions {
  &__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &--1 {
      background-image: url(../../img/home/home-action-1.jpg);
      margin-bottom: $gap-sm;
    }
    &--2 {
      background-image: url(../../img/home/home-action-2.jpg);
    }
    &--3 {
      background-image: url(../../img/home/home-action-3.jpg);
    }
  }

  &__item-icon {
    margin-top: $gap-md;
    font-family: "proza-libre-bold";
    font-size: 1.8rem;

    &--1 {
      color: $color-primary !important;
    }
  }
}

.moz-p-home__section {
  padding-top: 8rem;
}

.moz-p-home__services {
  font-size: $font-size-normal;

  &--answer {
    margin-bottom: 5rem;
  }
}

.moz-p-home__newsletter-form {
  font-size: $font-size-normal;
  label {
    font-size: $font-size-normal !important;
    top: -1.2rem;
  }

  i::before {
    font-size: $font-size-lg !important;
  }
}

.moz-p-home__newsletter-success-message {
  font-size: $font-size-normal;
  color: $color-success;
}
