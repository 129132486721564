.moz-c-pricelist {
  width: 100%;
  padding: 0.4rem 1rem;
  margin: 0.5rem 1.5rem;
  margin-bottom: 2rem;

  &__content {
    @include centerChildren;
  }

  &__content-text {
    margin: 0;
    font-size: 1.8rem;
    margin-left: 0.7rem;
    cursor: pointer;
  }
}
