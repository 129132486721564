.moz-u-bg-primary {
  background-color: $color-primary !important;
}

.moz-u-center-children {
  @include centerChildren;
}

.moz-u-margin-bottom-big {
  margin-bottom: $gap-xl;
}

.moz-u-primary-color {
  color: $color-primary !important;
}
