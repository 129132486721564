.moz-p-destination {
  &__header {
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    background-image: url(../../img/destinations/destination-hero.jpg);
  }

  &__header-content {
    text-align: center;
    color: $color-white;
    @include centerChildren;
    flex-direction: column;
    padding: 12rem 0;

    h1 {
      text-shadow: $shadow-small;
    }

    p {
      text-shadow: $shadow-medium;
    }
  }
}
