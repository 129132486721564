.moz-c-testimonial {
  width: 100%;
  height: 11.5rem;
  background: url(../../img/home/testimonial-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5rem;

  &--1 {
    background-position: top;
  }

  &--2 {
    background-position: bottom;
  }

  &__image {
    border: 0.5rem solid white;
    width: 11.5rem;
    height: 11.5rem;
  }
}
