.moz-p-not-found {
  height: 100vh;
  max-width: 100vw;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../img/not-found/not-found.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  @include centerChildren;
  flex-direction: column;

  .heading-primary {
    text-align: center;
    color: $color-white;
    text-shadow: $shadow-small;
  }
}
