//Reset is done in _base.scss this file is for vendor reset purposes

body .bg-primary {
  background-color: $color-primary !important;
}

.MuiInputBase-root,
.MuiFormLabel-root,
.MuiAutocomplete-popper *,
.btn {
  font-size: $font-size-normal !important;
}

.bq-primary {
  border-left-color: $color-primary !important;

  .bq-title {
    color: $color-primary !important;
  }
}

span.MuiButton-label {
  font-size: $font-size-sm;
}

h4.MuiTypography-root {
  font-size: $font-size-lg;
}

p.MuiTypography-root,
span.MuiTypography-root,
h6.MuiTypography-root {
  font-size: $font-size-normal;
}

.MuiPickersToolbar-toolbar {
  background-color: $color-primary !important;
}

.MuiPickersDay-daySelected {
  background-color: $color-primary !important;
}

.MuiButton-textPrimary {
  color: $color-primary !important;
}

.breadcrumb {
  font-size: $font-size-normal !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $color-primary !important;
  &:hover {
    background-color: rgba(lighten($color-primary, 30%), 0.05) !important;
  }
}
.MuiRadio-colorSecondary {
  &:hover {
    background-color: rgba(lighten($color-primary, 30%), 0.05) !important;
  }
}

.table td,
.table th {
  font-size: $font-size-xs !important;
}

.navbar-toggler {
  font-size: $font-size-xl !important;
}
