.moz-c-travel-card {
  text-align: center;
  height: 100%;

  &__image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  &__body {
    padding: 2.4rem 1rem;
    display: flex;
    flex-direction: column;
  }

  &__button {
    padding-top: 1rem;
    margin-top: auto;
  }
}
