.moz-p-contact {
  background-size: cover;
  background-position: center;
  background-image: url(../../img/contact/contact-us.jpg);

  &__header-content {
    padding-top: 14rem;
    padding-bottom: 7rem;
  }

  &__header-content-wrapper {
    @include centerChildren;
    text-align: center;
    color: $color-white;
  }

  &__content-wrapper {
    margin-top: 10rem;
  }

  &__form {
    padding: 0 1rem;
    .form-control {
      font-size: $font-size-normal;
    }
    label {
      font-size: $font-size-normal !important;
      top: -1.2rem;
      left: 0.5rem;
    }

    i {
      left: -0.5rem;
      &::before {
        font-size: $font-size-lg !important;
      }
    }

    .md-form {
      margin-bottom: 3rem !important;
    }
  }

  &__form-header {
    padding: 2rem;
    background-color: $color-primary;
    margin-top: -5.5rem;
    margin-bottom: 6rem;
    border-radius: 0.5rem;
    text-align: center;
    color: $color-white;
  }

  &__action-icon {
    border-radius: 30%;
    background-color: $color-primary !important;
    font-size: 2.4rem !important;
  }

  &__action-text {
    font-size: $font-size-sm;
    white-space: nowrap;
  }

  &__actions {
    margin: 6rem 0;
  }
}
