::-webkit-scrollbar {
  width: 12px;
  background-color: lighten($color-primary, 75%);
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

  background-color: $color-primary;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
