.moz-l-progress {
  &--full-screen {
    background-image: linear-gradient(
      to bottom,
      darken($color-gray-light-0, 30%),
      $color-gray-light-0
    );
    width: 100%;
    height: 100vh;
    @include centerChildren;
  }

  &--universal {
    padding: 8rem 0;
    width: 100%;
    @include centerChildren;
  }
}
